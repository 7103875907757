/* Container principal que engloba todo o app */


/* Navbar esticada 100% da largura */
.Navbar-private {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 10px;

}

/* Container que engloba o menu lateral e o conteúdo principal */
.content-container {
    display: flex;
    flex: 1;
    height: calc(100vh - 60px); /* Subtrai a altura da Navbar */
}

/* Menu lateral fixo à esquerda */
.E-Menu {
    width: 230px;
    height: 100%;
    top: 60px; /* Alinha abaixo da Navbar */
    left: 0;
}

/* Container para o conteúdo principal */
.main-content {
    flex: 1;
    padding: 20px;
    height: calc(100vh - 60px); /* Subtrai a altura da Navbar */
    overflow-y: auto; /* Adiciona rolagem se necessário */
}

/* Estilos para os itens do menu lateral */
.menu-lateral {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 15px;
}
