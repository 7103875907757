/* -------------------------------------------- */



.boy-left{
    height: auto;
    width: 200px;
    float: right;
    margin-top: 40px;
    margin-right: 40px;
}

.intro{
    font-size: 40px;
    margin-top: 100px;
    margin-left: 40px;
    font-family: sans-serif;
}
.bnt-price-home{
    margin-left: 40px;
    font-family: sans-serif;
    text-decoration: none;
    color: #F4F3F7;
    padding: 5px 30px;
    background:#6E971D;
    border-radius: 5px;
    font-size: 20px;
    transition: transform 0.3s ease;
}
.bnt-price-home:hover{
    background: none;
    border: solid 3px  #6E971D;
    color: #6E971D;
    transform: scale(1.1);
}
/* Estilo para o contêiner dos blocos */
.bloco-container {
    display: flex; /* Usa o modelo de layout flexível */
    justify-content: center; /* Centraliza os elementos horizontalmente */
    flex-wrap: wrap; /* Permite que os elementos quebrem para a próxima linha */
    text-align: center; /* Centraliza o conteúdo dentro dos blocos */
    margin: auto; /* Margem automática para centralizar na página */
    margin-bottom: 20px; /* Adiciona uma margem na parte inferior do contêiner */
    clear: both; /* Limpa elementos flutuantes */
}

/* Pseudo-elemento usado para limpar elementos flutuantes */
.bloco-container::after {
    content: "";
    display: table;
    clear: both;
}

/* Estilo para os blocos brancos */
.bloco {

    height: auto; /* Altura automática */
    width: 250px; /* Largura fixa */
    border-radius: 10px; /* Borda arredondada */
    text-align: center; /* Centraliza o conteúdo dentro dos blocos */
    margin: 10px; /* Distância entre cada bloco */
    padding: 15px; /* Preenchimento interno */
    box-sizing: border-box; /* Garante que a margem não aumente a largura total */
}


.robo{
    height: auto;
    width: 100px;
    margin-top: 20px;
}
