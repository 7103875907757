


/* ------------------------------------------ */
/* Class pagina de preço */
.emp-value{
    border: solid 3px #3dc076 !important;
}
.ofer-ndv {
    position: absolute;
    width: 80px; /* Largura do quadrilátero */
    height: 80px; /* Altura do quadrilátero */
    background-color: #3dc076; /* Cor de fundo (pode ser alterada) */
    clip-path: polygon(0% 100%, 0% 0%, 100% 0%, 0% 100%); /* Define os pontos do quadrilátero */
  }
  
.bg-square{
    width: 300px;
    height: auto;
    border: solid 3px rgb(150, 150, 150);
    border-radius: 10px;
}
.check-price{
    color: rgba(0, 128, 0, 0.555);
    height: auto;
    width: 30px;
}

.bloco-container-price {
    display: flex;
    flex-direction: row;
    gap: 20%; /* define o espaçamento entre os itens de um contêiner flexível. */
}
.exp{
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 50px;
    margin-bottom: 50px;
}
@media screen and (max-width: 768px) {
    .bloco-container-price {
        gap: 0;
        flex-direction: column;
    }
}


/* ------------------------------------------- */

.boy-left{
    height: auto;
    width: 200px;
    float: right;
    margin-top: 40px;
    margin-right: 40px;
}

.intro{
    font-size: 40px;
    margin-top: 100px;
    margin-left: 40px;
    margin-right: 30%;
    font-family: sans-serif;
}


