:root{
    --bg-green: #6E971D;
    --color-green-logo:#3dc076;
    --bg-white: #F4F3F7;
    --color-purple: #7F4796;
}
.background-primary{
    transition: background-color 0.5s ease;
}
.background-defaulth{
    transition: background-color 0.5s ease;
}
.text-color{
    transition: background-color 0.5s ease;
}
/* Cores do tema */
.btn-green{
    border: 1px solid  #D7DAE1;
    background: #5ABCAE;

    box-shadow: none !important;
    border: none !important;
    
    
}
.toggler-Nav{
    border: none!important;
}

.bg-defaulth-light{
    background: #D7DAE1;
    color: rgb(105, 105, 105);
    transition: background-color 0.5s ease;
}
.bg-primary-light{
    background: #F4F3F7;
}
.color-primary-light{
    color: #F4F3F7;
}
.disabled-color-light{
    color: rgb(105, 105, 105);
}
.cl-text-light{
    color: #1A1A1A;
}
.hr-light{
    background: #D7DAE1;
    border-bottom: solid 2px #D7DAE1;
    margin-left: 10%;
    margin-right: 10%;
}
/* .content {
    color: rgb(105, 105, 105);Cor do texto para tema claro
  } */
/* tema escuro */
.bg-defaulth-dark{
    background: #1F1F25;
    color: #FFFFFF;
    transition: background-color 0.5s ease;
}

/* .bg-default-dark .content {
    color: #FFFFFF; Cor do texto para tema escuro
  } */
/* -------------------------------------------- */
/* Classes Globais */
.border-10{
    border-radius: 10px;
    padding: 20px;
}
.font-bold{
    font-weight: bold;
}
/* Navbar class */
.nav-item {
    margin-right: 10px; /* Adiciona uma margem à direita de 10px para todos os itens de navegação */
  }
  
  .nav-item:last-child {
    margin-right: 0; /* Remove a margem à direita do último item de navegação para evitar espaço extra */
  }
.select-route{ 
    color: #A3D2B7 !important;
}
.btn-text-white{
    color: #F4F3F7;
    font-weight: bold;
}

.btn-text-white:hover{
    color: #6E971D;
    font-weight: bold;
}
.out-button-green{
    border-radius: 5px;
    transition: transform 0.3s ease;
}
.out-button-green:hover{
    background: #6E971D;
    transform: scale(1.1);
}
.out-text-green{
    color: #6E971D;
    font-weight: bold;
}
.out-text-green:hover{
    color: #F4F3F7;
    font-weight: bold;
}


.button-green{
    background: var(--bg-green);
    color: var(--bg-white);
    border: none;
    border-radius: 5px;
    transition: transform 0.3s ease;
}
.button-green:hover{
    background: none;
    border: solid 3px  #6E971D;
    transform: scale(1.1);
}
.text-btn-10{
    margin-left: 10px;
    margin-right: 10px;
}


/* -------------------------------------------- */

.center-horizontal{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.margin-top-10{
    margin-top: 10px;
}
.margin-right-10{
    margin-right: 10px;
}
.margin-top-60{
    margin-top: 60px;
}
.margin-top-40{
    margin-top: 40px;
}
.margin-top-30{
    margin-top: 30px;
}
.margin-top-20{
    margin-top: 20px;
}

.margin-left-40{
    margin-left: 40px;
   
}
.margin-right-40{
    margin-right: 40px;
  
}
.padding-left-10{
    padding-left: 10px;
}
.padding-top-40{
    padding-top: 40px;
}
.padding-10{
    padding: 10px;
}
.padding-bottom-10{
    padding-bottom: 10px;
} 
.selected-menu{  /* Cor da pagina selecionada */
    color: #A3D2B7 !important;
}
.container-center{ /*Centraliza tanto na vertical quanto da horizontal */
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
}

.flex-wrap{
    display: flex;
    flex-wrap: wrap; /* Permite que as divs quebrem para a próxima linha */
}

.Btn-theme {
    background: var(--color-green-logo);
    color: var(--bg-white);
    padding: 10px;
    border: none;
    border-radius: 5px;
    transition: background 0.4s ease, transform 0.3s ease;
    position: fixed; /* Posicionamento fixo */
    bottom: 20px; /* Distância do fundo */
    right: 20px; /* Distância da direita */
    z-index: 10;
}
  /*Botão de alterar o Tema  */
.Btn-theme:hover {
    background: none;
    color: var(--color-purple);
    transform: scale(1.3);
}

.vh{ /* apagar depois */
    height: 100vh;
}
/* ProgressBar Inicio */



/* ProgressBar Fim */